import './App.css';
import React from 'react';
import { AddTags } from './MyComponents/AddTags';
import { TagList } from './MyComponents/TagList';

import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [tags, setTags] = React.useState([]);
  // const [captions, setCaptions] = React.useState(["All beautiful things are around you.","A picture is a poem without words.","Test"]);

  let captions = [
    {
      sno: 1,
      tags: "beautiful,scene,mumbai",
      image: "https://source.unsplash.com/featured?mumbai",
      caption: "All beautiful things are around you."
    },
    {
      sno: 2,
      tags: "picture,abstract,painting",
      image: "https://source.unsplash.com/featured?abstract",
      caption: "A picture is a poem without words."
    },
    {
      sno: 3,
      tags: "test1,test2",
      image: "https://source.unsplash.com/featured?flower",
      caption: "Just like a flower, I’ll grow on you"
    },
    {
      sno: 4,
      tags: "test3,test4",
      image: "https://source.unsplash.com/featured?technology",
      caption: "Every programmer is an author."
    }
  ]

  const addTags = event => {
    if (event.key === "Enter" && event.target.value !== "" && event.target.value.includes(",") !== true) {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    } else if (event.key === "Enter" && event.target.value.includes(",") === true) {
      setTags([...tags, ...event.target.value.split(",")]);
      event.target.value = "";
    }

  }

  const removeTags = indexToRemove => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const captionize = () => {
    if (tags.length < 3) {
      toast.error('Enter at least 3 tags.');
    } else if (tags.length !== 0) {
      const id = toast.loading("Searching for captions, Please wait!!");
      let formData = new FormData();
      let tag_lists = tags.join(",")
      formData.append('lists', tag_lists);
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
      axios.post("https://captionizer-api.herokuapp.com/captions", formData, config)
        .then((response) => console.log(response.data))
        .then((response) => toast.update(id, { render: "Found some captions!!", type: "success", isLoading: false, autoClose: 5000 }))
        .catch(error => { console.error(error) })

    }
  }

  return (
    <div>
      <AddTags tags={tags} addTags={addTags} removeTags={removeTags} captionize={captionize} />
      <TagList caption={captions}/>
      <ToastContainer
        theme="dark"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;