import React from 'react'
import tag from './tag.svg';

export const AddTags = (props) => {
    return (
        <div className="wrapper">
            <div className="title">
                <img src={tag} alt="tag-icon" />
                <h2>Tags</h2>
            </div>
            <div className="content">
                <p>Press enter or add a comma after each tag</p>
                <ul>
                    {
                        props.tags.map((tag, index) =>
                        <li key={index}>
                            <span>{tag}</span>
                            <i className="uil uil-multiply" onClick={() => props.removeTags(index)}></i>
                            </li>)
                    }
                    <input type="text" onKeyUp={e => (e.key === "Enter" ? props.addTags(e) : null)}/>
                </ul>
            </div>
            <div className="details">
                <button onClick={props.captionize}>Captionize</button>
            </div>
        </div>
    )
}
