import React from 'react'

import profile from './Icons/Profile.svg'
import options from './Icons/Options.svg'
import like from './Icons/Like.svg'
import comment from './Icons/Comment.svg'
import share from './Icons/Share.svg'
import bookmark from './Icons/Bookmark.svg'

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import "swiper/swiper-bundle.min.css";
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";

import SwiperCore, {
    EffectCards
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectCards]);

export const TagList = (props) => {
    return (
        <div className="container">

            <Swiper effect={'cards'} grabCursor={true} keyboard={{ "enabled": true }} className="mySwiper">
                {
                    props.caption.map((captions) =>{
                        return <SwiperSlide key={captions.sno}>
                        <div className="template-container">
                            <div className="template-header">
                                <div className="template-profile">
                                    <img src={profile} alt="Profile" />
                                </div>
                                <div className="template-account-name">
                                    <div className="template-account-name-text">
                                        <span>Your_name</span>
                                    </div>
                                </div>
                                <div className="template-options">
                                    <img src={options} alt="Options" />
                                </div>
                            </div>
                            <div className="template-image">
                                <img src={captions.image} alt="Preview" />
                            </div>
                            <div className="interact">
                                <img className="like-icon interact-icon" src={like} alt="Like" />
                                <img className="comment-icon interact-icon" src={comment} alt="Comment" />
                                <img className="share-icon interact-icon" src={share} alt="Share" />
                                <img className="bookmark-icon interact-icon" src={bookmark} alt="Bookmark" />
                            </div>
                            <div className="likes">
                                <h1>1,269 likes</h1>
                            </div>
                            <div className="caption">
                                <p>Your_name <span className="caption-text">{captions.caption}</span></p>
                            </div>
                            <div className="post-time">
                                <p>2 minutes ago</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    })
                }
            </Swiper>

        </div>
    )
}